<template>
  <div class="bigImage-all" v-if="dialogVisible">
    <span>
      <i class="el-icon-close" @click="close"></i>
    </span>
      <img :src="'https://rtd-tms.oss-cn-shenzhen.aliyuncs.com/'+image" alt="">
    <!-- <el-dialog :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
    </el-dialog> -->
    <!-- <div class="cover"></div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      image: ''
    }
  },
  methods: {
    init (data) {
      this.image = data
      this.dialogVisible = true
    },
    close () {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="less">
.bigImage-all {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: rgba(0,0,0,0.1);
  z-index: 3333;
  text-align: center;
  .el-icon-close {
    position: fixed;
    right: 26px;
    top: 5px;
    font-size: 38px;
    color: #333;
  }
  .cover {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.1);
  }
  img {
    max-width: 90vw;
  }
}
</style>
